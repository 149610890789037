.weather {
  background-color: #fff;
  padding: 50px;
  color: rgb(37, 37, 37);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  text-align: center;
}

.weather h1 {
  color: #006699;
}

.weather h2 {
  font-size: 14px;
  
}

.weather .data {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create two columns */
  grid-gap: 10px; /* Add some spacing between columns and rows */
  align-items: start; /* Align items from the top */
  margin-top: 30px;
}

.weather p {
  padding: 20px;
  border-radius: 3px;
  background-color: #ebebee;
  border: solid 1px #ccc;
}

.weather p strong {
  display: block;
  font-size: 12px;
}

.owi {
  font-size: 80px !important;
  margin-top: 30px
}

/* --- Dark mode --- */
@media (prefers-color-scheme: dark) {
	body {
		background-color: #2b2b2b;
		color: #ccc !important;
	}

  .weather {
    background-color: #444;
  }

  .weather h1 {
    color: #0099cc;
  }

  .weather h2 {
    color: white;
  }

  .owi {
    color: #ebebee;
  }

  .weather p {
    background-color: #7a7a7a;
    color: white;
    border: solid 1px #4e4e4e;
  }

}

/* Small desktop sizing issues --- */
@media only screen and (max-width: 1410px) {
	.inner {
		width: 95%;
	}
}

/* --- Mobile styles --- */
@media only screen and (max-width: 800px) {
  .weather {
    border-radius: 0;
    padding: 30px;
    width: 100%;
  }
}