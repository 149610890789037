/* --- Reset --- */
* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
  padding: 0px;
  margin: 0px;
}

html, body { 
  height: 100%; 
  width: 100%; 
}

body {
 font-family: 'Poppins', sans-serif;
 font-weight: 300;
 background-color: #ebebee;
 color: black;
}

::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder { 
 color:    #999;
 font-family: 'Poppins', sans-serif;
 font-weight: 300;
}

p {
 font-size: 18px;
}

a:hover, input, textarea, select, button {
 -o-transition:.5s;
 -ms-transition:.5s;
 -moz-transition:.5s;
 -webkit-transition:.5s;
 transition:.5s;
 appearance: none;
 -webkit-appearance: none;
  border-radius: 0px;
  font-family: 'Poppins', sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

/* --- Main styles --- */

.wholeApp {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 65px);
  width: 100%;
  overflow-y: auto;
  padding: 10px;
}

footer {
  padding: 20px;
  text-align: center;
  height: 65px;
}

footer p {
  font-size: 16px;
}

footer a {
  color: #0099cc;
  text-decoration: none;
  font-weight: bold;
}

footer a:hover {
  text-decoration: underline;
}